.animatewidth {
    -webkit-transition: width .1s ease;
    -moz-transition: width .1s ease;
    -o-transition: width .1s ease;
    transition: width .1s ease;

    width:100%;
}

audio, video {
    box-sizing: unset;
}
#root {
    background: #fafafa;
}
body {
    background: #fafafa;
    font-size: 11pt;
}
em {
    font-size: .7em;
    color: #0069b7!important;
}
.content > p > div {
    height: .2em;
}
mark {
    padding: 0 .2em .15em .2em;
}
b > i {
    color: #c10;
}

.color.red {background-color:#db282830!important;border-color:#db282840!important}
.color.orange {background-color:#f2711c30!important;border-color:#f2711c40!important}
.color.yellow {background-color:#fbbd0830!important;border-color:#fbbd0840!important}
.color.olive {background-color:#b5cc1830!important;border-color:#b5cc1840!important}
.color.green {background-color:#21ba4530!important;border-color:#21ba4540!important}
.color.teal {background-color:#00b5ad30!important;border-color:#00b5ad40!important}
.color.blue {background-color:#2185d030!important;border-color:#2185d040!important}
.color.violet {background-color:#6435c930!important;border-color:#6435c940!important}
.color.purple {background-color:#a333c830!important;border-color:#a333c840!important}
.color.pink {background-color:#e0399730!important;border-color:#e0399740!important}
.color.brown {background-color:#975b3330!important;border-color:#975b3340!important}
.color.grey {background-color:#76767630!important;border-color:#76767640!important}
.color.black {background-color:#1b1c1d30!important;border-color:#1b1c1d40!important}

.blink {
    animation: blinker 5s ease-out infinite;
}
@keyframes blinker {
    70% {
        opacity: .5;
    }
}
.blinkmiddle {
    animation: blinkermiddle 1s ease-out infinite;
}
@keyframes blinkermiddle {
    90% {
        opacity: 0.5;
    }
}
.blinkfast {
    animation: blinkerfast 1s ease-out infinite;
}
@keyframes blinkerfast {
    90% {
        opacity: 0.5;
    }
}

.header-container .ui.attached.menu:not(.tabular) {
    background: none;
    border: none;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.header-container .ui.attached.segment {
    width: calc(100% + 2px);
    max-width: calc(100% + 2px);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background: none;
    border-bottom: 1px solid rgba(100,100,100,.15);
}

.header-container .ui.menu a.item {
    background: none;
    font-size: 1.15em;
}
.header-container .ui.menu .item {
    padding: .2em .5em;
    text-transform: none;
    color: rgba(0,0,0,.87);
    font-weight: 400;
}
.header-container .ui.divider {
    margin: .25em 0;
}
.ui.star.rating .active.icon {
    background: 0 0!important;
    color: #f2701d!important;
    text-shadow: 0 -1px 0 #ddc507,-1px 0 0 #ddc507,0 1px 0 #ddc507,1px 0 0 #ddc507!important;
}
.ui.grey.header {
    color: #444!important
}
h6.ui.header {
    font-size: 1em;
    font-weight: 700;
}
h6.ui.header .sub.header {
    font-size: .9em;
    color: #555;
}
h5.ui.header {
    font-size: 1em;
    font-weight: 500;
}
h5.ui.header .sub.header {
    font-size: .8em;
    color: #555;
}
h4.ui.header {
    font-size: 1.1em;
    font-weight: 500;
    color: #333;
}
h4.ui.header .sub.header {
    font-size: .8em;
    font-weight: 400;
    color: #555;
}
h3.ui.header {
    font-size: 1.5em;
    font-weight: 600;
}
h3.ui.header .sub.header {
    font-size: .7em;
    font-weight: 400;
    color: #555;
}
h2.ui.header {
    font-weight: 500;
    font-size: 2.2em;
}
h2.ui.header .sub.header {
    font-size: .6em;
    font-weight: 400;
}
h1.ui.header {
    font-weight: 500;
    font-size: 3.5em;
    line-height: 1em;
}
h1.ui.header .sub.header {
    font-size: .6em;
    font-weight: 400;
}
.ui.header {
    color: rgba(0,0,0,.95);
}
.ui.steps, .ui.steps:first-child {
    margin-bottom: 1em;
    _border: none;
}
.ui.steps .step {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin: 0 0;
    padding: .5em .5em;
    background: none;
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-right: none;
    -webkit-transition: background-color .1s ease,opacity .1s ease,color .1s ease,-webkit-box-shadow .1s ease;
    -webkit-transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
}
.ui.steps .step.active {
    cursor: auto;
    background: #f5f5f5;
    border-radius: 0;
    border: none;
}
.ui.tabular.menu {
    border-bottom: none;
}
.ui.tabular.menu .active.item {
    background: #fff;
    color: #f00;
    border-radius: 0!important;
    /* border-color: #70c8ff; */
    /* border-style: dashed;
    border-bottom: none; */
    border-top: 1px solid #f30;
}

.ui.steps .step:first-child {
    padding-left: .5em;
    border-radius: .28571429rem 0 0 .28571429rem;
}
.ui.steps .step:after {
    content: '';
    top: 50%;
    right: 0;
    border: 1px solid #eee;
    background-color: #f00;
    width: 0em;
    height: 0em;
    border-radius: 1em;
}
.ui.feed {
    margin: .5em 0;
}
.ui.feed>.event>.label+.provider {
    margin: .3em 0 .3em .5em;
}
.ui.feed>.event>.provider .extra {
    margin: 0 0 0;
    background: 0 0;
    padding: 0;
    color: rgba(0,0,0,.87);
}
.ui.comments .reply.form textarea {
    font-size: 1em;
    height: auto;
}
.ui.grid>.row {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: inherit;
    justify-content: inherit;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%!important;
    padding: 0;
    padding-top: 0rem;
    padding-bottom: 1rem;
}
.ui.grid>.column:not(.row), .ui.grid>.row>.column {
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding-left: 1rem;
    padding-right: 1rem;
    vertical-align: top;
    padding-top: 1em;
    padding-bottom: 1em;
}

/* .ui.grid>.row:first-child{
    padding-top: 0rem;
    padding-bottom: 0rem;
} */
/* .ui.grid>.column:not(.row), .ui.grid>.row>.column {
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding-left: 0rem;
    padding-right: .5em;
    vertical-align: top;
} */
/* .ui.grid>.column:not(.row), .ui.grid>.row>.column:first-child{
    position: relative;
    display: inline-block;
    width: 6.25%;
    padding-left: .5em;
    padding-right: .5em;
    vertical-align: top;
} */
/* .ui.celled.grid>.column:not(.row), .ui.celled.grid>.row>.column {
    padding: .5em;
} */
.ui.card, .ui.cards>.card {
    background: rgba(255, 255, 255, .7);
    border: 1px solid rgba(100,100,100,.2);
    overflow: hidden;
    border-radius: .5em;
    overflow: visible;
    -webkit-box-shadow: none; /*0 1px 3px 0 rgba(100,100,100,.2), 0 0 0 1px rgba(100,100,100,.2);*/
    box-shadow: none; /*0 1px 3px 0 rgba(100,100,100,.2), 0 0 0 1px rgba(100,100,100,.2);*/
    -webkit-transition: -webkit-box-shadow .1s ease,-webkit-transform .1s ease;
    transition: -webkit-box-shadow .1s ease,-webkit-transform .1s ease;
    -o-transition: box-shadow .1s ease,transform .1s ease;
    transition: box-shadow .1s ease,transform .1s ease;
    transition: box-shadow .1s ease,transform .1s ease,-webkit-box-shadow .1s ease,-webkit-transform .1s ease;
    z-index: '';
}
.ui.card>:first-child.content, .ui.cards>.card>:first-child.content {
    padding: 4em 1em;
}
.ui.card>:first-child.image, .ui.cards>.card>:first-child.image {
    border-radius: 1em 1em 0 0!important;
}
.ui.card>:first-child.circular, .ui.cards>.card>:first-child.circular {
    padding: 1em;
    border-radius: 10em!important;
}
.ui.card .meta, .ui.cards>.card .meta {
    color: rgba(0,0,0,.8);
}
.ui.card>.extra, .ui.cards>.card>.extra {
    /* padding: 0.5em; */
    /* background: #fff; */
    color: #444;
}
.ui.card>:first-child, .ui.cards>.card>:first-child {
    border-radius: .15em .15em 0 0!important;
    border-top: none!important;
    overflow: hidden;
}
.ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui) {
    font-weight: 100;
    font-size: 1.28571429em;
    margin-top: -.21425em;
    line-height: 1.28571429em;
}
.ui.four.statistics .statistic {
    min-width: 25%;
    overflow: hidden;
}
.ui.statistic>.label, .ui.statistics .statistic>.label {
    padding-top: .5em;
    font-family: sans-serif;
    font-size: 1.7em;
    font-weight: 100;
    color: rgba(0,0,0,.5);
    text-transform: uppercase;
    text-align: center;
}

.ui.attached.segment, .ui.segment {
    box-shadow: none;
    _margin: 1rem 0;
    padding: 1.5em 1.5em;
    border-radius: 0;
    border: 1px dashed rgba(71, 185, 255, .8)
}

.ui.basic.table tbody tr {
    border-bottom: 1px solid rgba(0,0,0,.1);
    vertical-align: top;
}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
  width: 20px;
}
.ui.basic.striped.table tbody tr:nth-child(2n) {
    background-color: rgba(200,200,200,.05)!important;
}
.ui.table {
    background: rgba(255, 255, 255, .8);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: rgba(0,0,0,.87);
}
th .ui.header {
    white-space: nowrap;
}
.ui.basic.table {
    background: rgba(255, 255, 255, .8);
    box-shadow: none;
    border: 1px dashed rgba(71, 185, 255, .8)
}

.segment > .ui.basic.table {
    border: none;
}

.ui.menu {
    /* font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; */
    background: none;
    font-weight: 400;
    border: 1px solid rgba(200,200,200,.5);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}
.ui.menu .item {
    background: rgba(255, 255, 255, .3);
}
.ui.menu .item>.floating.label {
    padding: .3em .5em;
    margin: -.5em 0 0 -2.5em!important;
}
.ui.menu .item>i.icon {
    opacity: .9;
    float: none;
    margin: 0 .15em 0 0;
}
.ui.vertical.menu .item {
    display: block;
    background: 0 0;
    border-top: none;
    border-right: none;
    padding: 1em 0em;
}
/* .ui.menu:not(.vertical) .item {
    background: #fafafa;
} */
.ui.menu .red.active.item, .ui.red.menu .active.item {
    background: rgba(255, 255, 255, .9);
    font-weight: 600;
}
.ui.menu .active.item {
    background: rgba(255, 255, 255, .9);
    font-weight: 600;
    /* box-shadow: none; */
    /* border-radius: 0; */
    /* border: none; */
    /* border-top: 2px solid #000; */
    /* margin-top: .2em; */
}
.ui.secondary.menu .item {
    border-radius: 0;
}
.ui.secondary.menu .active.item:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: rgba(0,0,0,.05);
    border-radius: 0;
}
.ui.secondary.menu .active.item {
    background: none;
    color: rgba(0,0,0,.95);
    border-radius: 0;
    font-weight: 600;
}
.ui.vertical.menu {
    background: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ui.button {
    text-decoration: none;
    border-radius: .2em;
    box-shadow: none;
    color: #333;
    background: rgba(255, 255, 255, .6);
}
.ui.button:hover {
    background: rgba(255, 255, 255, .8);
}
.ui.yellow.button, .ui.yellow.buttons .button {
    background-color: #ffe500;
    color: #fff;
    text-shadow: none;
    background-image: none;
}
.ui.basic.button, .ui.basic.buttons .button {
    padding: .5em .5em;
    /* background: transparent none!important; */
    color: rgba(0,0,0,.6)!important;
    font-weight: 400;
    border-radius: .2em;
    /* border: 1px solid rgba(100,100,100,.2); */
    text-transform: none;
    text-shadow: none!important;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ui.basic.button:hover, .ui.basic.buttons:hover .button:hover {
    background: rgba(150,150,150,.5)!important;
}
.ui.buttons .button:first-child {
    border-top-left-radius: .2em;
    border-bottom-left-radius: .2em;
    border: 1px solid rgba(100,100,100,.2);
    margin-left: 0;
}
.ui.buttons .button:last-child {
    border-top-right-radius: .2em;
    border-bottom-right-radius: .2em;
    border: 1px solid rgba(100,100,100,.2);
}
.ui.button, .ui.buttons .button {
    border: 1px solid rgba(100,100,100,.2);
}
.ui.buttons .or {
    border: none;
}
.ui.button, .ui.icon.button, .ui.icon.buttons .button {
    padding: .5em .5em .5em;
}
.ui.button:last-child {
    margin: 0 0 0 0;
}
.ui[class*="right labeled"].icon.button {
    padding-top: .75em;
    padding-bottom: .75em;
    padding-right: 3.5em!important;
    padding-left: 1em!important;
    border-radius: 3em;
}
.ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
    position: relative;
    padding-left: 3.37142857em!important;
    padding-right: 1.3em!important;
}
.ui.label>.detail {
    font-weight: 800;
    float: right;
}
.ui.sixteen.column.table td {
    /* width: 6.25%; */
    width: auto;
}
.ui.circular.button {
    width: 2.5em;
    height: 2.5em;
    padding: .75em;
}
.ui.one.cards>.card {
    display: -ms-flexbox;
    display: flex;
    margin: .5em 0em;
    float: none;
    border-radius: 0;
    border: 1px dashed rgba(71, 185, 255, .8)
}
.ui.one.card:last-child {
    margin-bottom: 1em;
}
.ui.one.card:first-child {
    margin-top: 1em;
}
i.brown.icon {
    color: #9f7046!important;
}
i.yellow.icon {
    color: #e8a700!important;
}
i.inverted.bordered.green.icon, i.inverted.circular.green.icon {
    background-color: #0e7e28!important;
    color: #fff!important;
}
i.inverted.green.icon {
    color: #0e7e28!important;
}
.ui.label {
    display: inline-block;
    margin: 0 .14285714em;
    /* padding: .5833em .833em; */
    border: none;
    border-radius: .2em;
    background-color: rgba(100, 100, 100, .1);
}
.ui.circular.large.label, .ui.circular.large.labels .label {
    width: 2.8em;
    height: 2.8em;
    margin: 0;
}
.ui.circular.label, .ui.circular.labels .label {
    min-width: 2em;
    min-height: 2em;
    padding-top: 0.6em!important;
    line-height: 1em;
    text-align: center;
    border-radius: 500rem;
}
.ui.yellow.label, .ui.olive.label {
    color: #000!important;
}
.ui.green.label {
    background-color: #2f9647!important;
    border-color: #2f9647!important;
}
.ui.tag.label, .ui.tag.labels .label {
    font-size: 1em;
    margin-bottom: 1em;
    margin-right: 1.15em;
    position: relative;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0 .28571429rem .28571429rem 0;
    transition: none;
    border: none;
}
i.icon {
    width: 1.18em;
    height: 1em;
    font-size: 1em;
    font-weight: 300;
}
.labeled .ui.label, .labeled .ui.labels .label {
    min-width: 5em;
    font-size: .95em;
    color: #555;
}
.normal .ui.label, .normal .ui.labels .label {
    min-width: 2em;
    font-size: .95em;
    color: rgba(0, 0, 0, .8);
}
.ui.multiple.dropdown>.label {
    background: #f0fffe;
    color: #444444;
}
.variable .ui.label {
    background-color: #93ebff;
}

.ui.comments .comment .comments {
    margin: 0 0 .5em 2.5em;
    padding: 1em 0 1em 1em;
}
.ui.progress {
    position: relative;
    display: block;
    max-width: 100%;
    border: 1px solid rgba(100,100,100,.15);
    margin: 1em 0 2.5em;
    box-shadow: none;
    background: rgba(200,200,200,.2);
    padding: 0;
    border-radius: .28571429rem;
}
.ui.progress:last-child {
    margin: 0 0 0em;
}
.ui.tiny.progress {
    font-size: .85714286rem;
    border: none;
}
.ui.progress>.label {
    top: 0;
}
.ui.header>.icon+.content {
    padding-left: .25em;
    vertical-align: middle;
}
.ui.header {
    color: rgba(0, 0, 0, .95);
}


.ui.pagination.menu {
    margin: 0;
    display: inline-flex;
    vertical-align: middle;
    border: none;
    background: none;
    border-radius: .5em;
}
.ui.pagination.menu .active.item {
    border-top: none;
    padding-top: .92857143em;
    background-color: #fff;
    border-radius: .25em;
    border: 1px solid #aaa;
    color: #D21;
    font-weight: bold;
    text-shadow: 1px 0px 0 #fff;
    box-shadow: none;
}

.ui.form .field>label {
    display: block;
    margin: 0 0 .28571429rem 0;
    color: #0069b7!important;
    font-size: 1.05em;
    font-weight: 500;
    text-transform: none;
}

.ui.form .fields {
    margin-bottom: .5em;
}
.ui.form .fields:last-child {
    margin-bottom: 0;
}
.ui.form .fields .wide.field {
    /* width: 6.25%;
    margin: 0 0 .5em 0;
    padding-left: .5em;
    padding-right: .5em; */
}
/* .ui.form .fields {
  margin: 0 -.25em .5em -.25em;
}
.ui.form .fields>.field,
.ui.form .fields .wide.field,
.ui.form [class*="equal width"].fields>.field, 
.ui[class*="equal width"].form .fields>.field {
    padding-left: .25em;
    padding-right: .25em;
  }
.ui.form [class*="equal width"].fields>.field:first-child, 
.ui.form .fields .wide.field:first-child,
.ui.form .fields>.field:first-child{
  padding-left: .25em;
  padding-right: .25em;
}

.ui.form [class*="equal width"].fields>.field:first-child, .ui.form .fields .wide.field:first-child, .ui.form .fields>.field:first-child {
    padding-left: 0em;
    padding-right: 0em;
}

.ui.form .fields:last-child {
    margin: 0 -.25em 0 -.25em;
} */
.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0;
    outline: 0;
    min-height: 2.8em;
    -webkit-appearance: none;
    line-height: 1em;
    padding: .6em 1em;
    font-size: 1em;
    font-weight: bold;
    background: rgba(255, 255, 255,1);
    border: 1px solid rgba(34,36,38,.2);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease,border-color .1s ease;
}
.ui.multiple.search.dropdown>input.search {
    min-height: auto;
}
.ui.form input:focus {
    background:#fff;
    font-weight: bold;
    color: #321;
}
.ui.form input:read-only {
    background: #fafafa;
    font-weight: bold;
    color: #555;
    text-shadow: 0 1px #ffffff;
    font-style: italic;
    border: 1px solid rgba(100, 100,100, .3);
}
.ui.form input:read-only:focus {
    border: 1px solid rgba(230, 230,230, 1);
    font-weight: bold;
    font-style: italic;
    background: #fafafa;
}
.ui.form textarea {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.ui.form textarea:read-only {
    background: #fafafa;
    font-weight: bold;
    color: #555;
    text-shadow: 0 1px #ffffff;
    font-style: italic;
    border: 1px solid rgba(100, 100,100, .3);
}
.ui.massive.input {
    font-size: 5em;
}
.ui.massive.input input {
    padding: 0 .2em;
    color: #ff6200;
    /* background: #f0f0f0; */
    font-weight: bold;
    text-align: center;
    /* border: none; */
}

.ui.modal {
    box-shadow: none;
}
.ui.modal>.content {
    background: #fafafa;
}
.ui.fullscreen.modal {
    width: 95%!important;
    left: auto!important;
    margin: 1em auto;
}
.ui.fullscreen.modal>.header {
    padding-right: 1.25rem;
}
.ui.fullscreen.scrolling.modal {
    left: auto!important;
}

.ui.divider {
    margin: 1rem 0;
    line-height: 1;
    height: 0;
    font-weight: 700;
    text-transform: none;
    letter-spacing: normal;
    color: rgba(0,0,0,.85);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 1px solid rgba(27, 27, 27, 0.1);
    border-bottom: 1px solid rgba(255,255,255,.15);
}
.ui.horizontal.divider:before {
    background-position: right 0em top 50%;
}
.ui.horizontal.divider:after {
    background-position: right 0em top 50%;
}
.ui.selection.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    min-height: 2.8em;
    background: #fff;
    display: inline-block;
    /* padding: .7em 2.1em .7em 1em; */
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
    font-weight: 600;
}
.ui.multiple.dropdown {
    padding: 0.35em 2.1em .2em 0.35em;
}
.ui.left:not(.top):not(.bottom).pointing.dropdown>.left.menu {
    box-shadow: none;
    left: auto!important;
    right: 100%!important;
    margin: 0 1em 0 0;
    background: #fff;
    border: 1px solid rgb(112, 200, 255);
}
.ui.left:not(.top):not(.bottom).pointing.dropdown>.left.menu:after {
    box-shadow: none;
    top: 1em;
    left: auto;
    right: -.25em;
    margin: 0;
    transform: rotate(135deg);
    border: none;
    border-left: 1px solid rgb(112, 200, 255);
    border-top: 1px solid rgb(112, 200, 255);
}

.arrowmenurecent {
    height: 1.2em;
    width: 1.2em;
    background: #f5f5f5;
    position: relative;
    right: -1.65em;
    top: 1em;
    float: right;
    transform: rotate(-45deg);
    border-radius: 0 0 1em;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
}

.arrowmenuadmin {
    height: 1.2em;
    width: 1.2em;
    background: #fafafa;
    position: relative;
    right: -2em;
    top: -1.2em;
    float: right;
    transform: rotate(-45deg);
}
.arrowmenuadmin1 {
    height: 1em;
    width: 1em;
    background: #fafafa;
    /* position: fixed; */
    margin-right: -1.1em;
    top: 0em;
    float: right;
    transform: rotate(-45deg);
}

.ui.blue.header {
    color: #0069b7!important;
}
.ui.red.header {
    color: #df1414!important
}
i.red.icon {
    color: #d80000!important;
}
i.grey.icon {
    color: rgb(97, 81, 86)!important;
}
.ui.message .header+p {
    margin-top: 0.25em;
    font-size: 1.2em;
}
.ui.message:last-child {
    margin-bottom: 1em;
}
.ui.dimmer {
    background-color: rgba(2, 2, 0, 0.85);
}

i.inverted.bordered.blue.icon, i.inverted.circular.blue.icon {
    background-color: #016fc1!important;
    color: #fff!important;
}
i.inverted.circular.yellow.icon {
    background-color: #fbbd08!important;
    color: #000!important;
}
i.inverted.circular.teal.icon {
    background-color: #78e5ff!important;
    color: #000!important;
}

.ui.checkbox label, .ui.checkbox+label {
    display: inline-block;
}

.error.field .ui.input .ui.label {
    border-color: #ee8855;
}
.ui.form .field.error input:not([type]):focus, .ui.form .field.error input[type=date]:focus, .ui.form .field.error input[type=datetime-local]:focus, .ui.form .field.error input[type=email]:focus, .ui.form .field.error input[type=file]:focus, .ui.form .field.error input[type=number]:focus, .ui.form .field.error input[type=password]:focus, .ui.form .field.error input[type=search]:focus, .ui.form .field.error input[type=tel]:focus, .ui.form .field.error input[type=text]:focus, .ui.form .field.error input[type=time]:focus, .ui.form .field.error input[type=url]:focus, .ui.form .field.error select:focus, .ui.form .field.error textarea:focus {
    background: #fff6f6;
    border-color: #ff2200;
    color: #fd4800;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ui.form .field.error input:not([type]), .ui.form .field.error input[type=date], .ui.form .field.error input[type=datetime-local], .ui.form .field.error input[type=email], .ui.form .field.error input[type=file], .ui.form .field.error input[type=number], .ui.form .field.error input[type=password], .ui.form .field.error input[type=search], .ui.form .field.error input[type=tel], .ui.form .field.error input[type=text], .ui.form .field.error input[type=time], .ui.form .field.error input[type=url], .ui.form .field.error select, .ui.form .field.error textarea, .ui.form .fields.error .field input:not([type]), .ui.form .fields.error .field input[type=date], .ui.form .fields.error .field input[type=datetime-local], .ui.form .fields.error .field input[type=email], .ui.form .fields.error .field input[type=file], .ui.form .fields.error .field input[type=number], .ui.form .fields.error .field input[type=password], .ui.form .fields.error .field input[type=search], .ui.form .fields.error .field input[type=tel], .ui.form .fields.error .field input[type=text], .ui.form .fields.error .field input[type=time], .ui.form .fields.error .field input[type=url], .ui.form .fields.error .field select, .ui.form .fields.error .field textarea {
    background: #fff6f6;
    border-color: rgba(255, 0, 0, .3);
    color: #fd4800;
    border-radius: '';
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
    opacity: 1;
    background: #f5f5f5;
    border: 1px solid #eaeaea;
}
.ui.items a.item:hover, .ui.link.items>.item:hover {
    text-decoration: underline;
}
.ui.disabled.dropdown {
    cursor: default;
    opacity: 1;
    pointer-events: none;
    background: #fafafa;
    color: #555;
    font-style: italic;
    font-weight: bold;
    border: 1px solid #dadada;
}
.ui.dropdown .menu>.item {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    opacity: 1;
    padding: .6rem .8rem!important;
    font-weight: 500;
}
.ui.dropdown .menu>.disabled.item {
 font-weight: 500;   
}

textarea::placeholder, .ui.form input::placeholder, .ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: rgba(175, 175, 175, 1) !important;
    font-style: italic;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.ui.purple.button, .ui.purple.buttons .button {
    background-color: #8f639e!important;
}
.ui.teal.label, .ui.teal.labels .label {
    background-color: #2d9095!important;
}
.ui.green.button, .ui.green.buttons .button {
    background-color: #488756;
}
.ui.teal.button, .ui.teal.buttons .button {
    background-color: #15a69f;
}


/*ANIMATION*/
.coloranimatered {
    animation: colorred 3s ease infinite;
}
@keyframes colorred {
	0% {
		color: #c31;
        font-weight: 400;
	}
	40% {
        color: #f00;
	}
	70% {
		color: #000;
    }
    
	100% {
        color: #c31;
        font-weight: bold;
	}
}
.bganimateblue {
    animation: bgroundblue 3s ease infinite;
}
@keyframes bgroundblue {
	0% {
		background-color: #2185d0;
	}
	40% {
		background-color: #3195e0;
	}
	70% {
		background-color: #379;
    }
    
	100% {
		background-color: #2185d0;
	}
}
.bganimatebgred {
    animation: bgroundred 3s ease infinite;
}
@keyframes bgroundred {
	0% {
		background-color: #f00;
	}
	40% {
		background-color: #d00;
	}
	70% {
		background-color: #c00;
    }
    
	100% {
		background-color: #f00;
	}
}
.bganimatebgdark {
    animation: bgrounddark 3s ease infinite;
}
@keyframes bgrounddark {
	0% {
		background-color: #fff;
        color: #000;
	}
	40% {
		background-color: #444;
        color: #fff;
	}
	70% {
		background-color: #000;
        color: #fff;
    }
    
	100% {
		background-color: #fff;
        color: #000;
	}
}
.bganimateiconborder {
    animation: border 3s ease infinite;
}
@keyframes border {
	0% {
		border: .3em solid rgba(50,50,50,.1);
	}
	40% {
		border: .3em solid rgba(50,50,50,.3);
	}
	70% {
		border: .3em solid rgba(50,50,50,.5);
    }
    
	100% {
		border: .3em solid rgba(50,50,50,.1);
	}
}
.bganimateicon {
    background: linear-gradient(85deg, rgba(150, 150, 150, .1), rgba(150, 150, 150, .3), rgba(150, 150, 150, .1));
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.increment .field input{
    min-height: 2.2em!important;
    border-radius: 0!important;
}
.ticketing .segment {
    padding: 1em!important;
    border-radius: .5em;
}
.custom .menu {
    /* background: #f5f5f5!important; */
}

/*ERROR*/
span.error {
    /* background: rgba(212, 212, 212, 0.4); */
    font-size: .8em;
    color: #f31;
    font-weight: bold;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*REACT CALENDAR*/
.react-calendar-timeline .rct-sidebar-header {
    margin: 0;
    color: #ffffff;
    background: #0ab4ca;
    _border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    -webkit-box-sizing: border-box;
    _box-sizing: border-box;
    border-bottom: 1px solid #fff;
    overflow: hidden;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -webkit-box-sizing: provider-box;
    box-sizing: provider-box;
    margin: 0;
    border-bottom: 1px solid #fff;
}
.react-calendar-timeline .rct-header .rct-label-group {
    padding: 0 5px;
    position: absolute;
    top: 0;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #fff;
    color: #ffffff;
    background: #0ab4ca;
    border-bottom: 1px solid #fff;
    cursor: pointer;
}
.react-calendar-timeline .rct-header .rct-label.rct-first-of-type {
    border-left: 1px solid #fff;
}
.react-calendar-timeline .rct-header .rct-label {
    position: absolute;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #fff;
    color: #fff;
    background: #00bcd4;
    border-bottom: 1px solid #fff;
    cursor: pointer;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: 1px solid #fff;
    -webkit-box-sizing: provider-box;
    box-sizing: provider-box;
    position: absolute;
    z-index: 40;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background: rgba(250,250,250,.5);
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    border-left: 1px solid #fff;
    background: #fff;
    z-index: 30;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    background: rgba(255, 47, 47, 0.5);
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    border-left: 1px solid #fff;
    background: rgba(200,200,200,.3);
    z-index: 30;
}
.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
    z-index: 1000;
}

.react-datepicker-wrapper {
    display:block;
}
.react-datepicker__input-container {
    position: relative;
    display: block;
}

/*FILEPOND*/
.filepond--root .filepond--drop-label {
    min-height: 3em;
}
.filepond--panel-root {
    border-radius: 0;
    background-color: rgba(255,255,255,.6);
}
.filepond--item-panel {
    border-radius: .25em;
}
.filepond--panel-top:after {
    content: "";
    position: absolute;
    height: 2px;
    left: 0;
    right: 0;
    bottom: -1px;
    background-color: inherit;
    display: none;
}
.filepond--panel-bottom {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    border-top: none!important;
    display: none;
}
.filepond--root {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 0em;
    font-size: 1rem;
    line-height: normal;
    /* font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif; */
    font-weight: 450;
    text-align: left;
    text-rendering: optimizeLegibility;
    direction: ltr;
    contain: layout style size;
}
[data-filepond-item-state=processing-complete] .filepond--item-panel {
    background-color: #00679a;
}
.filepond--drop-label.filepond--drop-label label {
    display: block;
    margin: 0;
    padding: .5em 1em;
    border-radius: 10em;
    background: #d9d5cc;
    color: #444;
    font-weight: bold;
}
.filepond--label-action {
    text-decoration-color: #000;
    cursor: pointer;
    color: #b71402;
}
.filepond--root .filepond--list-scroller {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10em;
}

.chat {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.chat::-webkit-scrollbar {
    display: none;
}
.noscroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.noscroll::-webkit-scrollbar {
    display: none!important;
}

@media only screen and (max-width: 767px) {
    div::-webkit-scrollbar {
        display: none;
    }
    .ui.container {
        width: auto!important;
        margin-left: 0!important;
        margin-right: 0!important;
    }
    .ui.modal>.actions {
        padding: 1em !important;
    }
    .ui.grid>.stackable.stackable.row>.column, .ui.stackable.grid>.column.grid>.column, .ui.stackable.grid>.column.row>.column, .ui.stackable.grid>.column:not(.row), .ui.stackable.grid>.row>.column, .ui.stackable.grid>.row>.wide.column, .ui.stackable.grid>.wide.column {
        width: 100%!important;
        margin: 0 0!important;
        -webkit-box-shadow: none!important;
        box-shadow: none!important;
        padding: 0 0 1.5em 0 !important;
    }
    .ui.modal .content>.description {
        display: block;
        width: 100%!important;
        margin: 0!important;
        padding: .2rem 0!important;
        box-shadow: none;
    }
    /* .ui.form [class*="equal width"].fields>.field:last-child, .ui.form .fields .wide.field:last-child, .ui.form .fields>.field:last-child{
        padding-left: 0em;
        padding-right: 0em;
    }
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable)>.field, .ui[class*="equal width"].form:not(.unstackable) .fields>.field {
        width: 100%!important;
        margin: 0 0 0;
    }

    .ui.form [class*="equal width"].fields>.field:last-child, .ui.form .fields .wide.field:last-child, .ui.form .fields>.field:last-child {
        padding-left: .25em;
        padding-right: .25em;
    } */

    /* .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable)>.field, .ui[class*="equal width"].form:not(.unstackable) .fields>.field {
        margin: .5em 0 .15em 1em;
    } */
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.field, .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.fields, .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable)>.field, .ui[class*="equal width"].form:not(.unstackable) .fields>.field {
        width: 100%!important;
        margin: 0 0 .5em;
    }
}
@media only screen and (min-width: 1200px) {
    .ui.large.modal {
        width: 95%;
        margin: 0;
    }
}
@media only screen and (min-width: 1400px) {
    .ui.large.modal {
        width: 90%;
        margin: 0;
    }
}
@media only screen and (min-width: 1600px) {
    .ui.large.modal {
        width: 80%;
        margin: 0;
    }
    .ui.fullscreen.modal {
        width: 1400px!important;
    }
}
.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid rgba(100,100,100,.2);
    padding: 1em;
}
.editorClassName {
    min-height: 20em;
}

.portal-body {
    background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
    background: #fff;
}
.ui.form .field .search-input input {
    font-size: 1.5em;
    border-radius: 2em;
    background: rgba(0,0,0,.7);
    text-align: center;
    color: #fff;
    border: 5px solid rgba(50,50,50,.5);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 2em;
    padding: 5px 10px;
    white-space: nowrap;
}

.styles_button__3Vugn {
    background: #f31;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    transition: all 200ms ease;
}
.styles_button__3Vugn>img {
    width: 42px;
    height: 42px;
}